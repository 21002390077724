// src/components/Home.js
import React from 'react';
import Navbar from './Navbar';
import Homepage from './HomePage';


const Home = () => {
  return (
    <div>
      <Navbar/> 
      <Homepage/>
       
    </div>
  );
};

export default Home;
